<script>
export default {
  props: {
    initPage: {
      type: Number,
      required: true,
    },
    initPerPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      page: this.initPage,
      perPage: this.initPerPage,
    }
  },
}
</script>
