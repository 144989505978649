import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import Breadcrumbs from '@theme/components/molecule/Breadcrumbs'
import Wysiwyg from '@theme/components/utils/Wysiwyg'

export default {
  components: {
    AppImage,
    AppTitle,
    Breadcrumbs,
    Wysiwyg,
  },
  props: {
    breadcrumbs: {
      type: [Array, Boolean],
      default() {
        return []
      },
    },
    category: {
      type: [Object, Boolean],
      default: false,
    },
    description: {
      type: [Object, Boolean],
      default: false,
    },
    imageAlt: {
      type: [String, Boolean],
      default: false,
    },
    imageCrop: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.ContentHeader.image.crop
      },
    },
    imageHeight: {
      type: Number,
      default() {
        return this.$themeSettings.components.ContentHeader.image.height
      },
    },
    imageSource: {
      type: [String, Boolean],
      default: false,
    },
    imageUpscale: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.ContentHeader.image.upscale
      },
    },
    imageWidth: {
      type: Number,
      default() {
        return this.$themeSettings.components.ContentHeader.image.width
      },
    },
    perex: {
      type: [String, Boolean],
      default: false,
    },
    title: {
      type: [String, Boolean],
      default: false,
    },
  },
}
